module.exports = Colors = {
    yellow: '#FFFF00',
    grey: '#CCCCCC',
    greyDark: '#BBBBBC',
    blackSecond: '#252931',
    blackSecondDark: '#1C2027',
    lightGrey: '#4B4B4B',
    soupOrange: '#FFA500',
    black: '#000000',
    white: '#FFFFFF',
    whiteTransparent: 'rgba(255,255,255,0.5)',
    blackTransparent: 'rgba(0,0,0,0.5)',
    greyTransparent: 'rgba(190,190,190,0.5)',
    red: '#E02315',
    xdark: '#333',
    darkStatusBar: '#50535B',
    Orange: "#ca6d4f",
    LightOrange: "#FFB38C",
    HeavyOrange: "#A1412B",
    DarkOrange: '#9E621E',
    Pink: "#FFD1B8",
    DarkBlue: "#010353",
    Gray: "#9B99A9",
    DarkGreen: "#1A3B49",
    LightPink: "#FFD3C3",
    Green: "#B4D4BC",
    DarkPink: "#F8AB9E",
  }
  