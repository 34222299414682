import React from 'react';
import {
  Text,
  View,
  Image
} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import ImageBackground from '../Components/ImageBackground';

import Device from '../Utils/Device';
import Services from '../Utils/Services';

export default class Expired extends React.Component {
  render() {
    return(
        <ImageBackground>
            <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
                <Image
                  source={require('../Img/img_expired.png')}
                  resizeMode={'contain'}
                  style={[styleHelper.flexCenter, {width: Device.width * 0.5, height: Device.width * 0.5, marginBottom: Device.getSize(10)}]} />

                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter, {marginBottom: Device.getSize(10)}]}>
                    TABLE QR EXPIRED.
                </Text>

                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter]}>
                    Please approach service staff for assistance. Thank you.
                </Text>
            </View>
        </ImageBackground>
    );
  }
}
