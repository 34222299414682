import React from 'react';
import {
  Text,
  View,
} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import ImageBackground from '../Components/ImageBackground';
import Device from '../Utils/Device';

export default class Offset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return(
        <ImageBackground>
            <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter, {marginBottom: Device.getSize(10)}]}>
                    INCORRECT TIMEZONE.
                </Text>

                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter]}>
                    Please set your device timezone
                </Text>

                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter]}>
                    to your local timezone.
                </Text>
            </View>
        </ImageBackground>
    );
  }
}
